@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Lato", sans-serif;
  /* color: #777; */
  scroll-behavior: smooth;
  line-height: 1.8;
  font-size: 15px;
}

@keyframes fade-in-from-none {
  0% {
    opacity: 0;
    display: none;
  }
  1% {
    opacity: 0;
    display: flex;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}
@keyframes fade-out-to-none {
  0% {
    opacity: 1;
    display: flex;
  }
  99% {
    opacity: 0;
    display: flex;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
.fade-in {
  animation-name: fade-in-from-none;
  animation-duration: 0.35s;
}
.fade-out {
  animation-name: fade-out-to-none;
  animation-duration: 0.35s;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    /* --foreground: 224 71% 4%; */

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --font-sans: "Lato";
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    line-height: 2rem;
  }
}

.scroll-container {
  position: relative;
}
#whatson {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.scroller {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  padding-top: 30px;
  padding-bottom: 30px;
}
.scroller::-webkit-scrollbar,
.scroller .item::-webkit-scrollbar {
  display: none;
}
.scroller .item {
  flex: none;
  flex-direction: column;
  display: flex;
  border-radius: 7px;
  border: 1px solid #eee;
  position: relative;
  box-shadow: 2px 2px 10px 1px #888;
  text-overflow: ellipsis;
}

.scroll-spacer {
  min-width: 100px;
  min-height: 100px;
}

@media only screen and (max-width: 600px) {
  /* .scroller .item {
    min-width: 80%;
    max-width: 80%;
    margin-left: 10px;
    margin-right: 10px;
  } */

  .scroll-container {
    max-width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .post-title {
    font-size: 20px;
  }
  .post-body {
    font-size: 14px;
    margin-top: 5px;
  }
}

.scroll-arrow {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #eee;
  border: 1px solid #888;
  bottom: -60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll-arrow:hover {
  background-color: rgb(215, 236, 198);
}

#left-arrow {
  left: 25px;
}
#right-arrow {
  right: 25px;
}

@media only screen and (max-width: 800px) {
  .scroll-arrow {
    display: none;
  }
}

.animate-top {
  animation: animatetop 0.4s forwards;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
.deanimate-top {
  animation: deanimatetop 0.4s forwards;
}
@keyframes deanimatetop {
  0% {
    top: 0px;
    opacity: 1;
  }
  99% {
    opacity: 0;
    top: 0px;
  }
  100% {
    top: -300px;
    opacity: 0;
  }
}

#lg-top-nav {
  z-index: 102;
}
.topContainer {
  z-index: 100;
}
#navbarContainer {
  z-index: 100;
}
#lg-scrolled-nav {
  position: fixed;
  z-index: 105;
}
.ratty {
  position: absolute;
  z-index: 100;
}
.bert {
  color: #555;
  text-decoration: none;
}
.bert:hover {
  background-color: #6ed4d02a;
}
