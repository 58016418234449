@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Lato", sans-serif;
  color: #777;
  scroll-behavior: smooth;
  line-height: 1.8;
  font-size: 15px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation-name: fade-in;
  animation-duration: 0.5s;
}
